import React from 'react'
import {StaticQuery, graphql, Link} from 'gatsby'

import './all.sass'

const MorePosts = ({currentSlug}) => (
  
  <StaticQuery
  query={graphql`
    query RelatedPosts {
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { templateKey: { eq: "post" } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              image 
            }
          }
        }
      }
    }
  `}
  render={(data) => (
    <ul className="tag-item-wrapper">
      {data.allMarkdownRemark.edges.map(post => (
        <Link className={`tag-item tag-item-treats ${currentSlug === post.node.fields.slug ? `active-tag-item` : null}`} key={post.node.fields.slug} to={post.node.fields.slug}>
          <div className='tag-img' style={{background: `url(${post.node.frontmatter.image})`, backgroundSize: 'cover', backgroundPosition: '50%, 50%'}} />
          <div className='tag-img-label'>
            {currentSlug === post.node.fields.slug ? 
              <p style={{color: '#ee5da1'}}>Currently Viewing</p>
            : null}
            <h2 styke={{marginTop: '9px'}}>{post.node.frontmatter.title}</h2>
          </div>
        </Link>
      ))}
    </ul>
  )}
/>
)

export default MorePosts
