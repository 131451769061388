import React, { useState } from 'react'
import PropTypes from 'prop-types'
// import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share'
import Confetti from 'react-dom-confetti'

import Layout from '../components/Layout'
import PinkCard from '../components/PinkCard'
import MorePosts from '../components/MorePosts'
import EmailListForm from '../components/EmailListForm'
import Content, { HTMLContent } from '../components/Content'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  slug
}) => {
  const PostContent = contentComponent || Content

  const [confetti, setConfetti] = useState(false);

  return (
    <section className="section">
      {helmet || ''}
      <div className="container">
        <div className="content">

          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="section">
                <h1 className="title is-size-2 has-text-weight-bold is-bold-light" style={{color: '#606161'}}>
                  {title}
                </h1>
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: 18}}>
                  <FacebookShareButton style={{marginRight: 9}} url={`https://www.sarahgrand.com${slug}`}><FacebookIcon size={18} round/></FacebookShareButton>
                  <LinkedinShareButton style={{marginRight: 9}} url={`https://www.sarahgrand.com${slug}`}><LinkedinIcon size={18} round/></LinkedinShareButton>
                  <TwitterShareButton style={{marginRight: 9}} url={`https://www.sarahgrand.com${slug}`}><TwitterIcon size={18} round/></TwitterShareButton>
                  <PinterestShareButton style={{marginRight: 9}} url={`https://www.sarahgrand.com${slug}`}><PinterestIcon size={18} round/></PinterestShareButton>
                  <RedditShareButton style={{marginRight: 9}} url={`https://www.sarahgrand.com${slug}`}><RedditIcon size={18} round/></RedditShareButton>
                  <EmailShareButton style={{marginRight: 9}} url={`https://www.sarahgrand.com${slug}`}><EmailIcon size={18} round/></EmailShareButton>
                </div>
                {/* <p style={{color: '#606161'}}>{description}</p> */}
                <PostContent className="content markdown-content" content={content} />

              </div>
            </div>
          </div>
        </div>
        <br/>
        <PinkCard>
          <div style={{textAlign: 'center', width: '100%'}}>
            <h1 className='intro-title' style={{color: '#fff'}}>Check out Sarah's Etsy Shop!</h1>
            <p style={{fontSize: '1.2rem', marginBottom: 18}}>All sorts of crafts and spunky items for you, or the perfect gift for someone special!</p>
            <a className='unbutton' style={{cursor: 'pointer'}} href='https://www.etsy.com/shop/SarahGrandDesigns' target="_blank" rel="noopener noreferrer">
              <div style={{width: '100%', transform: 'translateX(50%)'}}>
                <Confetti 
                  active={confetti} 
                  config={{
                    angle: 45,
                    spread: 360, 
                    width: '10px',
                    height: '10px',
                    elementCount: 500,
                    stagger: 10,
                    dragFriction: 0.22,
                    colors: ['#ffffff', '#ebcae1', '#ee5da1']
                  }} />
              </div>
              <button onMouseEnter={() => setConfetti(true)} onMouseLeave={() => setConfetti(false)} style={{border: 'none', background: '#FFF', cursor: 'pointer'}}>
                Go to the shop
              </button>
            </a>
          </div>
        </PinkCard>
        <br/>
        <div className='content'>
          <MorePosts currentSlug={slug} />
        </div>
        <PinkCard>
          <div style={{width: '100%', textAlign: 'center'}}>
            <h1 className='intro-title' style={{marginBottom: 9, color: '#FFF'}}>Stay Inspired!</h1>
            <p style={{fontSize: '1.2rem', marginBottom: 24}}>Sign up to get notified when I publish a new post and get 20% off your first order!</p>
            <EmailListForm />
          </div>
        </PinkCard>
      </div>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet
            titleTemplate="%s | Blog"
          >
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
        slug={post.fields.slug}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`
